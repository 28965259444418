import { dashboardConstants } from './dasboard.constants';
import { getAllAssessments, getAllAssessmentHistory } from '../../../shared/services/firebaseService';
import { initiateAssessment } from '../../../shared/services/apiService';
import { setSnackMessage } from '../../../shared/app.action';
import { snackTypes } from '../../../constants/constants';

export  const setAssessment = (payload) => dispatch => {
    dispatch({
        type: dashboardConstants.SET_ASSESSMENT,
        payload: payload
    });
}

export  const updateAssessee = (payload) => dispatch => {
    dispatch({
        type: dashboardConstants.UPDATE_ASSESSEE,
        payload: payload
    });
}
export  const setAllAssessments = (payload) => dispatch => {
    dispatch({
        type: dashboardConstants.SET_ALL_ASSESSMENTS,
        payload: payload
    });
}

export  const setAssessmentHistory = (payload) => dispatch => {
    dispatch({
        type: dashboardConstants.SET_ASSESSMENTS_HISTORY,
        payload: payload
    });
}

export const getAssessments = () => dispatch => {
    getAllAssessments().then((res)=>{
        dispatch(setAllAssessments(res));
    })
}

export const getAssessmentHistory = () => (dispatch, getState) => {
    getAllAssessmentHistory(getState().appState.userStatus.user.uid).then((res)=> {
        dispatch(setAssessmentHistory(res));
    })
}

export const initAssessment = (assessmentId, assessee) => (dispatch, getState) => {
    const uid = getState().appState.userStatus.user.uid;
    initiateAssessment(uid, assessmentId, assessee)
    .then(
        (res)=> {
            dispatch(setSnackMessage({type: snackTypes.SUCCESS, message: res.message ? res.message : 'Assessment initiated'}))
            dispatch(setAssessment());
            dispatch(updateAssessee([]));
        },
        (err) => {
            dispatch(setSnackMessage({type: snackTypes.ERROR, message: err.message ? err.message : 'Some error occured. Please try again'}))
        }
    );
}