import { assignmentConstants } from './assessment.constant';

const assessmentState = {
  answerList: [],
  dataList: {
    form: {},
    info: {},
    instruction: {},
    quiz: {
      data: [],
    },
    thankyou: null,
  },
  component: 0,
  visibleQuestions: [],
  perPage: 1,
  currentPage: 0,
};

export default function (state = assessmentState, action) {
  switch (action.type) {
    case assignmentConstants.FETCH_DATA:
      if (action.questions) {
        var data = action.questions.map(function (val, i) {
          return { ...val, index: i };
        });
      }
      return {
        ...state,
        dataList: {
          ...state.dataList,
          info: {
            ...state.info,
            ...action.description,
          },
          instruction: {
            data: action.instruction,
          },
          quiz: {
            prompt: action.prompt,
            data: data,
          },
        },
      };
    case assignmentConstants.FETCH_FOOTNOTE:
      return {
        ...state,
        info: {
          ...state.info,
          ...action.description,
        },
      };
    case assignmentConstants.SET_ANSWER:
      return {
        ...state,
        answerList: Object.assign({}, state.answerList, {
          [action.payload.index]: action.payload.answer,
        }),
      };
    case assignmentConstants.SET_COMPONENT:
      return {
        ...state,
        component: action.component,
      };
    case assignmentConstants.ADD_DROPDOWN:
      return {
        ...state,
        dropdown: {
          ...state.dropdown,
          [action.field]: action.dropdown,
        },
      };
    case assignmentConstants.ADD_ASSESS_ID:
      return {
        ...state,
        assessment_id: action.assessment_id,
      };
    case assignmentConstants.SET_PAGE:
      const visibleQuestions = state.dataList.quiz.data.slice(
        (action.currentPage - 1) * state.perPage,
        action.currentPage * state.perPage
      );
      return {
        ...state,
        currentPage: action.currentPage,
        visibleQuestions: visibleQuestions,
      };
    default:
      return state;
  }
}
