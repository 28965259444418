import React from 'react';
import './AssessmentHistory.scss';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import SortIcon from '@material-ui/icons/Sort';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import { getAssessmentHistory } from '../shared/dashboard.action';
import HistoryItem from './historyItem/HistoryItem';

const AssessmentHistory = React.memo((props) => {
    const [historyList, setHistoryList] = React.useState(props.assessmentsHistory)
    const [sortOrder, setSortOrder] = React.useState('asc')
    const [sortBy, setSortBy] = React.useState('init')
    const { dispatch } = props;
    let searchKeyWord = '';
    
    React.useEffect(()=>{
        if(!props.assessmentsHistory.length) {
            dispatch(getAssessmentHistory());
        } else if(!historyList.length) {
            sortHistoryList(sortOrder, sortBy);
        }
    })

    const handleSearchChange = (e) => {
        searchKeyWord = e.target.value;
        sortHistoryList(sortOrder, sortBy);
    }

    const getAssessmentById = (id) => {
        return props.assessments.filter(assessment => assessment.id == id)[0];
    }

    const toggleSortOrder = () => {
        const order = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(order);
        sortHistoryList(order,sortBy);
    }

    const changeSortBy = (field) => {
        field === sortBy ? toggleSortOrder() : sortHistoryList(sortOrder, field);
        setSortBy(field);
    }

    const sortHistoryList = (order, field) => {
        setHistoryList(props.assessmentsHistory.filter((assessment) => {
            if(searchKeyWord) {
                return assessment.email.includes(searchKeyWord);
            }
            return true;
        }).sort((a,b) => {
            if(field === 'init') {
                return order === 'desc' ? Number(a.init_info.timestamp) - Number(b.init_info.timestamp) ? 1 : -1 :  Number(a.init_info.timestamp) - Number(b.init_info.timestamp) ? -1 : 1 ;
            } else {
                if(a.submission_timestamp && b.submission_timestamp) {
                    return Number(a.submission_timestamp) - Number(b.submission_timestamp) ? -1 : 1 ;
                } else {
                    return order === 'asc' ? a.submission_timestamp ? 1 : -1 : a.submission_timestamp ? -1 : 1
                }
            }
        }))
    }

    return (
        <div className='assessment-history'>
            <div className='flex-container align-center'>
                <SearchIcon />
                <TextField onChange={handleSearchChange} label="Search" className='full-width' variant="filled" />
                <Tooltip title="Sort by initiation time" aria-label="Sort by initiation time">
                    <SortIcon onClick={()=>changeSortBy('init')} fontSize='large'></SortIcon>
                </Tooltip>
            </div>
            <div className='header-container flex-container semi-bold-font'>
                <p className='header'>User email address</p>
                <p className='header'>Assessment</p>
                <p className='header'>
                    Assessment status
                    <Tooltip title="Sort by status" aria-label="Sort by status">
                        <ImportExportIcon onClick={()=>changeSortBy('status')} fontSize='small'></ImportExportIcon>
                    </Tooltip>
                </p>
            </div>
            {historyList && historyList.map(row => (
                <HistoryItem key={row.init_info.timestamp} historyItem={row} assessment={getAssessmentById(row.assessment_id)}></HistoryItem>
            ))
            }
        </div>
    );
});

function mapStateToProps(state) {
    return {
        assessmentsHistory: state.dashboard.assessmentsHistory,
        assessments: state.dashboard.assessments,
    }
}

export default connect(mapStateToProps)(AssessmentHistory);