import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    componentDidCatch(error, info) {
      this.setState({ hasError: true });
      console.log(error);
      console.log(info);
    }
  
    render() {
      if (this.state.hasError) {
        return (
          <div className='flex-container align-center direction-column'>
            <h1>Something went wrong.</h1>
            <h2>Please try again</h2>
          </div>
        );
      }
      return this.props.children;
    }
}

export default ErrorBoundary;