import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';    
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import './HistoryItem.scss';


const HistoryItems =  React.memo((props) => {

    const isAssessmentExpired = props.historyItem.submission_timestamp ? false : props.historyItem.init_info.timestamp < (moment().subtract(props.assessment.validity, 'days').format('x'));
    const getStatus = () => {
        if (isAssessmentExpired) {
            return moment(Date.now()).diff(moment(props.historyItem.init_info.timestamp).add(props.assessment.validity, 'days'), 'days') < 5 ? 'expired ' + moment(props.historyItem.init_info.timestamp).add(props.assessment.validity, 'days').fromNow() : 'expired on ' + moment(props.historyItem.init_info.timestamp).add(props.assessment.validity, 'days').format('D MMM YY'); 
        } else {
            if (props.historyItem.submission_timestamp) {
                return moment(Date.now()).diff(props.historyItem.submission_timestamp, 'days') < 5 ? 'submitted ' + moment(props.historyItem.submission_timestamp).fromNow() : 'submitted on ' + moment(props.historyItem.submission_timestamp).format('D MMM YY');
            } else {
                return moment(Date.now()).diff(props.historyItem.init_info.timestamp, 'days') < 5 ? 'initiated ' + moment(props.historyItem.init_info.timestamp).fromNow() : 'initiated on ' + moment(props.historyItem.init_info.timestamp).format('D MMM YY');
            }
        }
    }
    return (
        <div className='item-container'>
            <ExpansionPanel>
                <ExpansionPanelSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <div className='full-width'>
                        <div className='flex-container first-row'>
                            <div className='column'>{props.historyItem.email}</div>
                            <div className='column'>{props.assessment ? props.assessment.shortName : '-'}</div>
                            <div className={isAssessmentExpired ? 'column expired semi-bold-font status' : props.historyItem.submission_timestamp ? 'column submitted semi-bold-font status': 'column semi-bold-font status'}>{getStatus()}</div>
                        </div>
                        <div className='flex-container second-row'>
                            {
                                props.historyItem.report ? 
                                (
                                    <React.Fragment>
                                        <div className='column'>{props.historyItem.report && props.historyItem.report.key_measurement} :- </div>
                                        <div className='column'>{props.historyItem.report && props.historyItem.report.key_score.value} | </div>
                                        <div className='column'>{props.historyItem.report && props.historyItem.report.key_score.pair}</div>
                                    </React.Fragment>
                                )
                                :
                                null
                            }
                        </div>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className='details-row flex-container full-width'>
                        <div className='column'>Initiated at: {props.historyItem.init_info.timestamp ? moment(props.historyItem.init_info.timestamp).format('LLL') : '-'}</div>
                        <div className='column middle'>Submitted at: {props.historyItem.submission_timestamp ? moment(props.historyItem.submission_timestamp).format('LLL') : '-'}</div>
                        <div className='column flex-container align-center'>Report: 
                        {
                            props.historyItem.submission_timestamp ?
                            (
                                <div className='flex-container'>
                                    <a href={`https://assessments.ticktalkto.com/assessment/${props.historyItem.requestorId}/${props.historyItem.subscriptionId}?auth=true`} target='_blank' rel="noopener noreferrer">
                                        <AssessmentOutlinedIcon></AssessmentOutlinedIcon>
                                    </a>
                                </div>
                            )
                            :
                            '-'}</div>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
});

function mapStateToProps(state) {
    return {
        assessments: state.dashboard.assessments,
    }
}

export default connect(mapStateToProps)(HistoryItems);