import { dashboardConstants } from './dasboard.constants';

const dashboardState = {
    assessee: [],
    assessments:[],
    assessmentSelected:'',
    assessmentsHistory: [],
}

export default (state = dashboardState, action) => {
    switch (action.type) {
        case dashboardConstants.SET_ASSESSMENT:
            return {...state,assessmentSelected: action.payload};
        case dashboardConstants.UPDATE_ASSESSEE:
            return {...state,assessee: action.payload};
        case dashboardConstants.SET_ALL_ASSESSMENTS:
            return {...state,assessments: action.payload};
        case dashboardConstants.SET_ASSESSMENTS_HISTORY:
            return {...state,assessmentsHistory: action.payload};
        default:
            return state;
    }
}