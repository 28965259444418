import { appConstants } from './app.constant';
import { snackTypes } from '../constants/constants';
import { firebaseApp, firebaseDb } from '../shared/services/firebaseService';
import { authorizeUser } from './services/apiService';

export const setSnackMessage = (payload) => {
  return (dispatch) => {
    dispatch({
      type: appConstants.SET_SNACK,
      payload: {
        type: payload.type ? payload.type : snackTypes.INFO,
        duration: payload.duration ? payload.duratio : 4000,
        message: payload.message,
      },
    });
  };
};

export const loginSuccess = (user) => (dispatch) => {
  dispatch({
    type: appConstants.LOGIN_SUCCESS,
    user,
  });
};
export const loginFailure = (err) => (dispatch) => {
  dispatch({
    type: appConstants.LOGIN_FAILURE,
    err,
  });
};
export const logoutSuccess = (user) => (dispatch) => {
  dispatch({
    type: appConstants.LOGOUT_SUCCESS,
    user,
  });
};
export const logoutFailure = (err) => (dispatch) => {
  dispatch({
    type: appConstants.LOGOUT_FAILURE,
    err,
  });
};

export const requestVerify = () => (dispatch) => {
  dispatch({
    type: appConstants.VERIFY_REQUEST,
  });
};

export const verifySuccess = () => (dispatch) => {
  dispatch({
    type: appConstants.VERIFY_SUCCESS,
  });
};

export const setAssessmentIsAuthenticating = (payload) => (dispatch) => {
  dispatch({
    type: appConstants.SET_ASSESSMENT_AUTHENTICATING,
    payload,
  });
};

export const setAssessmentIsAuthenticated = (payload) => (dispatch) => {
  dispatch({
    type: appConstants.SET_ASSESSMENT_AUTHENTICATED,
    payload,
  });
};

export const setAssessment = (payload) => (dispatch) => {
  dispatch({
    type: appConstants.SET_ASSESSMENT,
    payload,
  });
};

export const loginUser = (email, pass) => (dispatch) => {
  dispatch(setSnackMessage({ type: snackTypes.INFO, message: 'Logging in' }));
  firebaseApp
    .auth()
    .signInWithEmailAndPassword(email, pass)
    .then((user) => {
      dispatch(
        setSnackMessage({
          type: snackTypes.SUCCESS,
          message: 'Login Successful',
        })
      );
      dispatch(loginSuccess(user));
    })
    .catch((err) => {
      dispatch(
        setSnackMessage({ type: snackTypes.ERROR, message: err.message })
      );
      dispatch(logoutFailure(err.message));
    });
};

export const logoutUser = () => (dispatch) => {
  firebaseApp
    .auth()
    .signOut()
    .then(() => {
      dispatch(logoutSuccess());
    })
    .catch((err) => {
      dispatch(logoutFailure(err.message));
    });
};

export const verifyUser = () => (dispatch) => {
  firebaseApp.auth().onAuthStateChanged((user) => {
    if (user !== null) {
      dispatch(loginSuccess(user));
    }
    dispatch(verifySuccess());
  });
};

export const authApi = (data) => (dispatch) => {
  authorizeUser(data)
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      if (res.status === 'success') {
        window.localStorage.setItem(
          'ticktalktoAssessmentEmail',
          res.message.email
        );
        window.location.href = res.message.link;
      } else if (res.status === 'failed') {
        dispatch(
          setSnackMessage({ type: snackTypes.ERROR, message: res.message })
        );
        dispatch(setAssessmentIsAuthenticating(false));
        dispatch(setAssessmentIsAuthenticated(false));
      }
    })
    .catch((err) => {
      dispatch(
        setSnackMessage({ type: snackTypes.ERROR, message: err.message })
      );
      dispatch(setAssessmentIsAuthenticating(false));
      dispatch(setAssessmentIsAuthenticated(false));
    });
};

export const loadSubscription = (req_id, sub_id) => (dispatch) => {
  dispatch(setAssessmentIsAuthenticating(true));
  const email = window.localStorage.getItem('ticktalktoAssessmentEmail');
  if (email && firebaseApp.auth().isSignInWithEmailLink(window.location.href)) {
    dispatch(signIn(email, req_id, sub_id));
  } else {
    const data = {
      requestor_uid: req_id,
      subscription_id: sub_id,
    };
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        data.location = {
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
        };
      });
    }
    dispatch(authApi(data));
  }
};

export const signIn = (email, req_id, sub_id) => (dispatch) => {
  firebaseApp
    .auth()
    .signInWithEmailLink(email, window.location.href)
    .then((res) => {
      window.localStorage.removeItem('ticktalktoAssessmentEmail');
      dispatch(getAssessmentForUser(req_id, sub_id));
    })
    .catch((err) => {
      dispatch(
        setSnackMessage({
          type: snackTypes.ERROR,
          message: err.message ? err.message : 'Sign in with email link failed',
        })
      );
      dispatch(setAssessmentIsAuthenticated(false));
      dispatch(setAssessmentIsAuthenticating(false));
    });
};

export const getAssessmentForUser = (req_id, sub_id) => (dispatch) => {
  dispatch(setAssessmentIsAuthenticating(true));
  firebaseDb
    .ref('native_assessment_subscription/' + req_id + '/' + sub_id)
    .once('value')
    .then((res) => {
      const assessment = res.val();
      dispatch(setAssessment(assessment));
    })
    .catch((err) => {
      dispatch(
        setSnackMessage({ type: snackTypes.INFO, message: 'Validating user' })
      );
      dispatch(setAssessmentIsAuthenticated(true));
      dispatch(setAssessmentIsAuthenticating(false));
      dispatch(loadSubscription(req_id, sub_id));
    });
};

export const initAssessment = (assessment) => (dispatch) => {
  let isValid = false;
  firebaseDb
    .ref(
      'native_assessment_users/' + firebaseApp.auth().currentUser.uid + '/email'
    )
    .set(firebaseApp.auth().currentUser.email);
  firebaseDb
    .ref('native_assessments/' + assessment.assessment_id + '/validity')
    .once('value')
    .then((res) => {
      if (Date.now() - assessment.init_info.timestamp <= res.val() * 86400000) {
        isValid = true;
      } else {
        isValid = false;
        dispatch(
          setSnackMessage({
            type: snackTypes.ERROR,
            message: 'Assessment is expired',
          })
        );
        dispatch(setAssessmentIsAuthenticated(false));
        dispatch(setAssessmentIsAuthenticating(false));
      }
    })
    .catch((err) => {
      isValid = false;
      dispatch(setSnackMessage({ type: snackTypes.ERROR, message: err }));
      dispatch(setAssessmentIsAuthenticated(false));
      dispatch(setAssessmentIsAuthenticating(false));
    });
  return isValid;
};
