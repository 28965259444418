import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import './InitiateAssessment.scss';
import EventNoteIcon from '@material-ui/icons/EventNote';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import * as d3 from 'd3-fetch';
import SelectEmail from './selectEmail/SelectEmail';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import {
  setAssessment,
  updateAssessee,
  getAssessments,
  initAssessment,
} from '../shared/dashboard.action';
import { setSnackMessage } from '../../../shared/app.action';
import { snackTypes } from '../../../constants/constants';

const InitiateAssessment = props => {
  const { dispatch } = props;
  const fileUpload = React.useRef(null);

  React.useEffect(() => {
    if (!props.assessments.length) {
      dispatch(getAssessments());
    }
  });

  const handleChange = e => {
    dispatch(setAssessment(e.target.value));
  };

  const assesseeUpdated = assessee => {
    dispatch(updateAssessee(assessee));
  };

  const initiateAssessment = () => {
    dispatch(initAssessment(props.assessmentSelected.id, props.assessee));
  };

  const uploadCSV = () => {
    fileUpload.current.click();
  };

  const handleUpload = e => {
    dispatch(
      setSnackMessage({ type: snackTypes.INFO, message: 'Uploading CSV file' })
    );
    const url = URL.createObjectURL(e.target.files[0]);
    d3.csv(url).then(
      data => {
        const emailArr = [];
        data.map(item => {
          const email =
            typeof item === 'object'
              ? item.email
                ? item.email
                : null
              : typeof item === 'string'
              ? item
              : null;
          if (email) {
            emailArr.push(email);
          }
        });
        dispatch(updateAssessee([...props.assessee, ...emailArr]));
        if (emailArr.length) {
          dispatch(
            setSnackMessage({
              type: snackTypes.SUCCESS,
              message: 'Uploaded successfully',
            })
          );
        } else {
          dispatch(
            setSnackMessage({
              type: snackTypes.INFO,
              message: 'No email found',
            })
          );
        }
      },
      err => {
        dispatch(
          setSnackMessage({
            type: snackTypes.ERROR,
            message: "Couldn't process CSV file",
          })
        );
        console.log(err);
      }
    );
  };

  return (
    <div className='initiate-assessment'>
      <div className='flex-container align-end'>
        <Button
          variant='contained'
          onClick={initiateAssessment}
          color='secondary'
          size='medium'
        >
          Initiate Assessment
        </Button>
      </div>
      <div className='flex-container full-width align-center top-margin'>
        <EventNoteIcon />
        <FormControl className='full-width select-assessment'>
          <InputLabel id='select-label'>Assessment</InputLabel>
          <Select
            labelId='select-label'
            value={props.assessmentSelected}
            onChange={handleChange}
            className='full-width'
          >
            {props.assessments.map(assessment => {
              return (
                <MenuItem value={assessment} key={assessment.id}>
                  {assessment.name}
                </MenuItem>
              );
            })}
          </Select>
          {props.assessment ? (
            <div></div>
          ) : (
            <FormHelperText>Select Assessment</FormHelperText>
          )}
        </FormControl>
      </div>
      <SelectEmail assessee={props.assessee} updateAssessee={assesseeUpdated} />
      <div className='flex-container align-end top-margin'>
        <Button variant='contained' size='medium' onClick={uploadCSV}>
          Upload CSV
        </Button>
        <input
          ref={fileUpload}
          onChange={handleUpload}
          className='file-upload'
          type='file'
          accept='text/csv    '
        ></input>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    assessee: state.dashboard.assessee,
    assessmentSelected: state.dashboard.assessmentSelected,
    assessments: state.dashboard.assessments,
  };
}

export default connect(mapStateToProps)(InitiateAssessment);
