import React from 'react';

const ErrorAssessment = () => {
    return (
        <div className='flex-container align-center direction-column'>
            <h1>Assessment cannot be loaded</h1>
            <h2>Please try again</h2>
        </div>
    );
};

export default ErrorAssessment;
