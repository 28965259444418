import React from 'react';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import EmailIcon from '@material-ui/icons/Email';
import './SelectEmail.scss';
import Avatar from '@material-ui/core/Avatar';
import ControlPointIcon from '@material-ui/icons/ControlPoint';

const SelectEmail = (props) => {
    const [isValidEmail, setValidity] = React.useState(true);

    const handleDelete = (index) => {
        let newUsers = [...props.assessee];
        newUsers.splice(index,1);
        props.updateAssessee(newUsers)
    }

    const validateEmail = (email) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleKeyPress = (e) => {
        if(e.key === 'Enter') {
            if(validateEmail(e.target.value)) {
                props.updateAssessee([...props.assessee, e.target.value])
                setValidity(true);
                e.target.value = '';
            } else {
                setValidity(false)
            }
        }
    }

    return (
        <div className='top-margin'>
            <div className='flex-container align-center full-width'>
                <EmailIcon />
                <TextField error={!isValidEmail}
                    label="Assessee Email"
                    helperText={isValidEmail ? "Press Enter after typing" : "Please enter valid email"}
                    placeholder='abc@gmail.com'
                    className='full-width select-email'
                    defaultValue=''
                    onKeyPress={handleKeyPress}
                />
            </div>
            <div className='flex-container direction-column align-center chips-container'>
            {
                props.assessee && props.assessee.length !== 0 ? props.assessee.map((email,index) => {
                return <Chip 
                        avatar={<Avatar>{email.substr(0,1).toUpperCase()}</Avatar>}
                        key={email}
                        label={email} 
                        onDelete={handleDelete.bind(this,index)} 
                        className='full-width chips'
                        color="primary"></Chip>
                })
                :
                <div className='flex-container align-center full-height'>
                    <ControlPointIcon /> Add Assessee
                </div>
            }
            </div>
        </div>
    );
};

export default SelectEmail;