import React from 'react';
import './LoadingScreen.css';

export default function LoadingScreen() {
  return (
    <div className='wrapperLoader'>
      <div className='centerLoading'>
        <p className ='textLoader'>Please wait</p>
        <span className='ouro ouro3'>
          <span className='left'>
            <span className='anim'></span>
          </span>
          <span className='right'>
            <span className='anim'></span>
          </span>
        </span>
      </div>
    </div>
  );
}
