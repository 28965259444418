import { reportConstants } from './report.constant';

const reportState = {
  user: {},
  report: {
    disclaimer: '',
    purpose: '',
    procedure: '',
    evaluation: '',
    recommendation: {
      main: '',
      additional: '',
    },
  },
};

export default function (state = reportState, action) {
  switch (action.type) {
    case reportConstants.USER_INFO:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case reportConstants.REPORT_DATA:
      return {
        ...state,
        report: {
          ...state.report,
          ...action.payload,
          recommendation: {
            ...state.report.recommendation,
            ...action.payload.recommendation,
          },
          result: {
            ...state.report.result,
            ...action.payload.result,
          },
          disclaimer: {
            ...state.report.disclaimer,
            ...action.payload.disclaimer,
          },
        },
      };
    default:
      return state;
  }
}
