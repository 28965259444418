let BASE_URL;
if (process.env.NODE_ENV === 'development') {
  BASE_URL = 'https://ticktalkto-test-api.appspot.com/';
} else {
  BASE_URL = 'https://ticktalkto-api.appspot.com/'
}

export const makePost = (url, data) => {
  return fetch(BASE_URL + '' + url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const initiateAssessment = (uid, assessmentId, emailList) => {
  const endPoint = 'initiateNativeAssessment';
  const data = {
    requestor_uid: uid,
    assessment_id: assessmentId,
    email_list: emailList,
    utm: 'dashboard',
  };
  return makePost(endPoint, data);
};

export const authorizeUser = (data) => {
  const endPoint = 'sendEmailAuthLink';
  return makePost(endPoint, data);
}

export async function createUser(email, utm) {
  const endPoint = 'initiateNativeTestAssessment';
  const data = {
    email: email,
    utm: utm,
  };
  const response = await makePost(endPoint, data);
  return await response.json();
}
