import React from 'react';
import { connect } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Button from '@material-ui/core/Button';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import InitiateAssessment from './initiateAssessment/InitiateAssessment';
import AssessmentHistory from './assessmentHistory/AssessmentHistory';
import ErrorBoundary from '../../shared/components/ErrorBoundary';
import { logoutUser } from '../../shared/app.action';
import './Dashboard.scss';

const theme = createMuiTheme({
    typography: {
      fontFamily: [
        'Montserrat',
        'sans-serif',
      ].join(','),
    },
  });

const Dashboard = (props) => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const logout = () => {
        props.dispatch(logoutUser());
    }
    return (
        <ErrorBoundary>
            <ThemeProvider theme={theme}>
            <div className='flex-container'>
                <Tabs className='side-tabs' value={value} onChange={handleChange} aria-label="Side navigation" orientation="vertical">
                    <Tab label="Initiate Assessment"  />
                    <Tab label="View Assessment History"  />
                    <Button variant='contained' size='medium' onClick={logout} className="logout">
                        logout <ExitToAppIcon fontSize="small"></ExitToAppIcon>
                    </Button>
                </Tabs>
                {
                    value === 0 ? <InitiateAssessment /> : <AssessmentHistory />
                }
            </div>
            </ThemeProvider>
        </ErrorBoundary>
    );
};

export default connect()(Dashboard);