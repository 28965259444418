let firebaseConfig = {};
if (process.env.NODE_ENV === 'development') {
    firebaseConfig = {
    apiKey: "AIzaSyC3ungUaW0x0B7d4xcCFEtlVhTNYaPK3Dw",
    databaseURL: "https://ticktalkto-test.firebaseio.com",
    projectId: "ticktalkto-test",
    authDomain: "ticktalkto-test.firebaseapp.com",
    appId: "1:565366401263:web:7d449088d90a44c6",
  }
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyB888NVDMQ1nnO1_eCI4U1d66tkVAYso-U',
    databaseURL: 'https://ticktalkto-deploy.firebaseio.com',
    projectId: 'ticktalkto-deploy',
    authDomain: 'ticktalkto-deploy.firebaseapp.com',
    appId: '1:968748340428:web:779904e59c0fe958d61e69',
  }
}

export default firebaseConfig;