export const appConstants = {
  SET_USER: 'SET_USER',
  REMOVE_USER: 'REMOVE_USER',
  SET_SNACK: 'SET_SNACK',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  VERIFY_REQUEST: 'VERIFY_REQUEST',
  VERIFY_SUCCESS: 'VERIFY_SUCCESS',
  SET_ASSESSMENT: 'SET_ASSESSMENT',
  SET_ASSESSMENT_AUTHENTICATING: 'SET_ASSESSMENT_AUTHENTICATING',
  SET_ASSESSMENT_AUTHENTICATED: 'SET_ASSESSMENT_AUTHENTICATED',
}
