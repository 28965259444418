import firebaseConfig from '../../constants/firebaseConstants';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

export const firebaseApp = app.initializeApp(firebaseConfig);
export const firebaseDb = firebaseApp.database();

export const getAllAssessments = () => {
  return firebaseDb
    .ref('/native_assessments/')
    .once('value')
    .then(function(snapshot) {
      return Object.keys(snapshot.val()).map(key => {
        return { name: snapshot.val()[key].name, id: key, shortName:snapshot.val()[key].short_name, validity:snapshot.val()[key].validity };
      });
    });
};

export const getAllAssessmentHistory = (uid) => {
    return firebaseDb.ref('/native_assessment_subscription/' + uid).once('value').then(function(snapshot) {
      if(snapshot.val()) {
        return Object.keys(snapshot.val()).map((key)=> {
          return {...snapshot.val()[key], requestorId: uid, subscriptionId: key};
        })
      } else 
      return [];
    })
}
