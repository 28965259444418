import React from 'react';
import { connect } from 'react-redux';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { setSnackMessage } from '../app.action';

class SnackMessage extends React.PureComponent {

    handleClose = () => {
        this.props.setSnack({});
    }

    render() {
        return (
            this.props.message ? 
                <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                open={this.props.message ? true : false}
                autoHideDuration={this.props.duration}
                onClose={this.handleClose}
                action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                    <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }>
                    <Alert elevation={6} variant="filled" severity={this.props.type}>{this.props.message}</Alert>
                </Snackbar>      
            :
            ''      
        );
    }
}

const mapStateToProps = (state) => {
	return {
		message: state.appState.toastState.message,
        duration: state.appState.toastState.duration,
        type: state.appState.toastState.type,
	}
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSnack: (payload)=> dispatch(setSnackMessage(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SnackMessage);
