import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import LoadingScreen from '../shared/components/LoadingScreen';
import ErrorAssessment from '../shared/components/ErrorAssessment';
import { loadSubscription, initAssessment, getAssessmentForUser } from '../shared/app.action';
const Assessment = React.lazy(() => import('./assessment/Assessment'));
const Report = React.lazy(() => import('./report/Report'));

class PsychometricsAuth extends PureComponent {
  constructor(props) {
    super(props);
    this.reqId = this.props.match.params.req_id;
    this.subId = this.props.match.params.sub_id;
    this.state = {
      component: LoadingScreen,
      isAssessmentValidated: false,
    };
  }

  verifyAssessment = () => {
    this.props.dispatch(initAssessment(this.props.assessment));
    this.setState({ isAssessmentValidated: true });
  };

  componentDidMount() {
    let isLoggedIn = false;
    if (this.props.location.search.startsWith('?auth=')) {
      isLoggedIn = this.props.location.search.replace('?auth=', '') == 'true' ? true : false;
    }
    if (isLoggedIn) {
      this.props.dispatch(getAssessmentForUser(this.reqId, this.subId));
    }
    else if (!this.props.assessment) {
      this.props.dispatch(loadSubscription(this.reqId, this.subId));
    }
  }

  componentDidUpdate() {
    if (this.props.isAuthenticating) {
      this.setState({
        component: LoadingScreen,
      });
    } else if (this.props.isAuthenticated && this.props.assessment) {
      if (this.props.assessment.report_timestamp) {
        this.setState({
          component: Report,
        });
      } else if (this.state.isAssessmentValidated) {
        this.setState({
          component: Assessment,
        });
      } else {
        this.verifyAssessment();
      }
    } else {
      this.setState({
        component: ErrorAssessment,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <this.state.component data={this.props.assessment} />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.appState.assessmentUser.isAuthenticated,
    isAuthenticating: state.appState.assessmentUser.isAuthenticating,
    assessment: state.appState.assessment,
  };
}

export default connect(mapStateToProps)(withRouter(PsychometricsAuth));
