import { combineReducers } from 'redux';
import appState from './app.reducer';
import assignmentState from '../app/assessment/shared/assessment.reducer';
import dashboardState from '../app/dashboard/shared/dashboard.reducer';
import reportState from '../app/report/shared/report.reducer';
export default combineReducers({
  appState: appState,
  assessment: assignmentState,
  dashboard: dashboardState,
  report: reportState,
});
