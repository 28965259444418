import React, { PureComponent, Suspense } from 'react';
import Dashboard from './app/dashboard/Dashboard';
import SnackMessage from './shared/components/SnackMessage';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import RouteGuard from './app/RouteGuard';
import PsychometricsAuth from './app/PsychometricsAuth';
import ErrorBoundary from './shared/components/ErrorBoundary';
import { verifyUser } from './shared/app.action';
const Register = React.lazy(() => import('./app/RegisterAssessment/Register'));
const Thankyou = React.lazy(() =>
  import('./app/RegisterAssessment/components/Thankyou')
);
const Login = React.lazy(() => import('./app/login/Login'));

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.props.dispatch(verifyUser())
  }
  render() {
    return (
      <ErrorBoundary>
        <Suspense
          fallback={<CircularProgress className='spinner'></CircularProgress>}
        >
          <SnackMessage />
          <Router>
            <Switch>
              <Route path='/login' component={Login} />
              <Route exact path='/register' component={Register} />
              <RouteGuard
                exact
                path='/register/success'
                isAuthenticated={
                  window.localStorage.getItem(
                    'ticktalktoAssessmentConfirmation'
                  ) !== null
                }
                pathName='/register'
                component={Thankyou}
              />
              <Route
                path='/assessment/:req_id/:sub_id'
                component={PsychometricsAuth}
              />
              <RouteGuard
                path='/dashboard'
                isAuthenticated={this.props.isAunthenticated}
                pathName='/login'
                component={Dashboard}
              />
            </Switch>
          </Router>
        </Suspense>
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAunthenticated: state.appState.userStatus.isAuthenticated,
  };
}
export default connect(mapStateToProps)(App);
