import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const RouteGuard = ({
  component: Component,
  pathName,
  isAuthenticated,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <React.Fragment>
        {isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: pathName, state: { from: props.location } }}
          />
        )}
      </React.Fragment>
    )}
  />
);

export default RouteGuard;
